export const motorRequest = {
  insuranceTypeEnum: "MOTOR_WEB",
  product: undefined,
  category: "Individual & Family",
  plan: "Motor Plan",
  motorfields: [
    {
      label: "Type of cover",
      name: "coverType",
      type: "select",
      required: true,
      options: [
        { key: "CI", value: "Comprehensive" },
        { key: "TPI", value: "Third party" },
        { key: "TPI", value: "Third Party Fire & Theft" },
      ],
    },
    {
      label: "Vehicle registration number",
      name: "registrationNumber",
      type: "text",
      required: true,
    },
    {
      label: "Year of Make",
      name: "yearOfMake",
      type: "date",
      required: true,
    },
    {
      label: "Value of the vehicle",
      name: "vehiclevalue",
      type: "number",
      required: true,
    },
    {
      label: "Select Vehicle category/usage",
      name: "vehicle-category",
      type: "select",
      options: [
        { key: "X_1_PRIVATE_INDIVIDUAL", value: "Private Individual" },
        { key: "X_4_PRIVATE_CORPORATE", value: "Private Corporate" },
        { key: "TAXI", value: "COMMERCIAL - TAXI" },
        { key: "HIRING_CARS_UBER", value: "COMMERCIAL - HIRING & UBER" },
        { key: "MINI_BUS", value: "COMMERCIAL - MINI BUS " },
        { key: "MOTOR_CYCLE", value: "COMMERCIAL - MAXI BUS" },
        { key: "MAXI_BUS", value: "MOTOR CYCLE" },
        { key: "AMBULANCE_HEARSE", value: "COMMERCIAL - MAXI BUS" },
        { key: "MAXI_BUS", value: "AMBULANCE / HEARSE" },
        {
          key: "OWN_GOODS_Z_300_LESS_3000_CC)",
          value: "GOODS CARRYING - PRIVATE/CORPORATE (UNDER 3000 CC)",
        },
        {
          key: "OWN_GOODS_Z_300_ABOVE_3000_CC",
          value: "GOODS CARRYING - PRIVATE/CORPORATE (ABOVE 3000 CC)",
        },
        { key: "ART_TANKERS", value: "ARTICULATORS & TANKERS" },
        {
          key: "GEN_CARTAGE_Z_301_LESS_3000_CC",
          value: "GOODS CARRYING - COMMERCIAL (UNDER 3000 CC)",
        },
        {
          key: "GEN_CARTAGE_Z_301_ABOVE_3000_CC",
          value: "GOODS CARRYING - COMMERCIAL (ABOVE 3000 CC)",
        },
        { key: "Z_802_ON_SITE", value: "INDUSTRIAL VEHICLE - ON SITE ONLY" },
        { key: "Z_802_ON_ROAD ", value: "Industrial Vehicle-On site and road" },
        { key: "GW1_CLASS_1", value: "COMMERCIAL - MAXI BUS" },
        { key: "MAXI_BUS", value: "GW1 CLASS1" },
      ],
    },

    {
      label: "Duration",
      name: "insurancePeriod",
      type: "select",
      options: [
        { key: "ONE_MONTH", value: "1 Month" },
        { key: "TWO_MONTHS", value: "2 Month" },
        { key: "THREE_MONTHS", value: "3 Month" },
        { key: "FOUR_MONTHS", value: "4 Month" },
        { key: "FIVE_MONTHS", value: "5 Month" },
        { key: "SIX_MONTHS", value: "6 Month" },
        { key: "SEVEN_MONTHS", value: "7 Month" },
        { key: "EIGHT_MONTHS", value: "8 Month" },
        { key: "NINE_MONTHS", value: "9 Month" },
        { key: "ONE_YEAR", value: "1 Year" },
      ],
    },
    {
      label: "Number of seats",
      name: "numberOfSeats",
      type: "number",
      required: true,
    },

    {
      label: "Vehicle Registration Certificate",
      name: "VRC",
      type: "file",
      required: true,
    },
  ],
};
