export const pharmaSecureRequest = {
  insuranceTypeEnum: "PHARMASECURE",
  product: undefined,
  category: "Special Protection",
  plan: "Pharma Secure",
  pharmasecurefields: [
    {
      label: "First Name",
      name: "firstName",
      type: "text",
      required: true,
    },
    {
      label: "Last Name",
      name: "lastName",
      type: "text",
      required: true,
    },
    {
      label: "Name of pharmaceutical company",
      name: "nameOfPharmaceuticalCompany",
      type: "text",
      required: true,
    },
    {
      label: "Phone Number ",
      name: "phoneNumber",
      type: "text",
      required: true,
    },
    {
      label: "Location",
      name: "location",
      type: "text",
      required: true,
    },
    {
      label: "Upload a list of your stock",
      name: "uploadStock",
      type: "file",
      required: true,
    },
    {
      label: "Upload the CVs of the Pharmacists",
      name: "uploadPharmacists",
      type: "file",
      required: true,
    },
  ],
};
