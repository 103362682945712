import {
  Ace,
  Assurance,
  Donewell,
  Enterprise,
  Hollard,
  Imperial,
  Milife,
  Millenium,
  Nationwide,
  Phoenix,
  Premiere,
  Provident,
  Prudential,
  SanlamAllianz,
  Serene,
  Sic,
} from "../../../../assets";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const OurPartnership = () => {
  const partnerData = [
    {
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610402/partner1_lapzeo.webp",
      title: "BANBO FITA PARTNERSHIP – MOTOR INSURANCE DRIVE",
      body1:
        "A strategic collaboration with FITA Autotech to provide a value-added motor-related services. This partnership is positioned to leverage the strengths of both organizations to provide a 360-vehicle protection and performance enhancement.",
      body2: "     This is intended to drive motor insurance.",
    },
    {
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610403/partner2_j6v8y4.webp",
      title: "BANBO WALTERGATES/CIBA PARTNERSHIP- SME INSURANCE DRIVE",
      body1:
        "A strategic partnership with Council for Indigenous Business Associations (CIBA) through Watergates to break into the retail and untapped market. This partnership covers several markets across the indigenous business in Ghana providing Fire insurance cover for SME’s and Life for the Business owners and Traders.",
      body2:
        "As brokers of the project, Banbo provides services from consulting to soliciting and claims assistance.",
    },
    {
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610403/partner3_usaty6.webp",
      title: "BANBO BUILT AFRICA PARTNERSHIP- UNTAPPED MARKET PENETRATION",
      body1:
        "A strategic collaboration to add value to the usage of digital platforms of Built Africa for accounting, payroll, invoicing, payment and other financial services. This partnership aims at taking insurance to the doorsteps of the market who needs it but rarely buys it.",
      body2:
        "Together with Built Africa, We are helping SMEs and entrepreneurs to understand and leverage insurance as a financial planning tool one business at a time.",
    },
  ];

  const partners = [
    { logo: `${Donewell}` },
    { logo: `${Prudential}` },
    { logo: `${Sic}` },
    { logo: `${Imperial}` },
    { logo: `${Millenium}` },
    { logo: `${Enterprise}` },
    { logo: `${Hollard}` },
    { logo: `${Phoenix}` },
    { logo: `${Provident}` },
    { logo: `${SanlamAllianz}` },
    { logo: `${Assurance}` },
    { logo: `${Ace}` },
    { logo: `${Premiere}` },
    { logo: `${Nationwide}` },
    { logo: `${Serene}` },
    { logo: `${Milife}` },
  ];
  return (
    <div>
      <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-[90rem] ">
        <h1
          data-aos="fade-up"
          data-aos-offset="200"
          data-aos-delay="200"
          data-aos-duration="1000"
          data-aos-once="true"
          className="text-3xl px-6 md:text-4xl font-bold mt-6 mb-12 text-[#003862] "
        >
          Our partnerships
        </h1>

        <div className="grid gap-10 row-gap-5 sm:row-gap-10 lg:grid-cols-3  sm:mx-auto">
          {partnerData.map((data, index) => (
            <div
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-duration="1000"
              data-aos-once="true"
              data-aos-delay={index * 200}
              style={{
                backgroundImage: `url(${data.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              className="rounded-3xl h-[35rem] "
            >
              <div className="bg-gradient-to-t rounded-3xl from-[#003862] flex h-[35rem] items-end">
                <div className="pb-6" key={index}>
                  <h1 className="text-white font-bold text-xl pb-4 px-10">
                    {data.title}
                  </h1>
                  <p className="text-white text-sm px-10">{data.body1}</p>
                  <p className="text-white py-4 px-10">{data.body2}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          <Swiper
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-duration="1000"
            data-aos-once="true"
            data-aos-delay="200"
            spaceBetween={10}
            loop={true}
            slidesPerView={3}
            navigation={false}
            autoplay={{
              delay: 1000,
              disableOnInteraction: false,
            }}
            pagination={false}
            modules={[Navigation, Pagination, Autoplay]}
            breakpoints={{
              0: {
                spaceBetween: 10,
                slidesPerView: 1,
              },
              375: {
                spaceBetween: 10,
                slidesPerView: 2,
              },
              575: {
                spaceBetween: 10,
                slidesPerView: 3,
              },
              767: {
                spaceBetween: 10,
                slidesPerView: 4,
              },
              1024: {
                spaceBetween: 10,
                slidesPerView: 6,
              },
            }}
            className="mySwiper"
          >
            {partners.map((data, index) => (
              <SwiperSlide key={index}>
                <img
                  className="w-32 m-auto flex justify-center items-center"
                  src={data.logo}
                  alt=""
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default OurPartnership;
