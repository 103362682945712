export const schoolInsuranceRequest = {
  insuranceTypeEnum: "SCHOOLINSURANCE",
  product: undefined,
  category: "Special Protection",
  plan: "School Insurance",
  Schoolinsurancefields: [
    {
      label: "Name of School",
      name: "school-name",
      type: "text",
      required: true,
    },
    {
      label: "Phone Number",
      name: "phone-number",
      type: "text",
      required: true,
    },
    { label: "Location", name: "location", type: "text", required: true },
    {
      label: "Upload list of equipment",
      name: "equipment-list",
      type: "file",
      required: true,
    },
    {
      label: "Number of teachers",
      name: "num-teachers",
      type: "number",
      required: true,
    },
    {
      label: "School Bus Registration Number",
      name: "bus-registration-num",
      type: "text",
      required: true,
    },
    {
      label: "Last Insurer of School Bus",
      name: "last-insurer",
      type: "text",
      required: true,
    },
  ],
};
