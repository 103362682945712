import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { HiMenuAlt3 } from "react-icons/hi";
import { LogoDark, LogoWhite } from "../assets";
import { NavLink, useLocation } from "react-router-dom";

const NavHeader = () => {
  const location = useLocation();

  const specialPaths = [
    "/about",
    "/buy-insurance",
    "/insurance-service",
    "/insurance-policy",
    "/insurance-quote",
  ];

  const isSpecialPath = specialPaths.some((path) =>
    location.pathname.startsWith(path)
  );

  const textColor = isSpecialPath ? "text-white" : "text-[#003862]";
  const bgColor = isSpecialPath
    ? "backdrop-blur-lg  bg-[#262626] bg-opacity-30"
    : "bg-white";
  const logo = isSpecialPath ? LogoWhite : LogoDark;
  const hoverColor = isSpecialPath ? "bg-white" : "bg-[#003862]";

  const navData = [
    {
      title: "About Us",
      link: "/about",
    },
    {
      title: "Buy Insurance",
      link: "/buy-insurance",
    },
    {
      title: "Blog",
      link: "/blog",
    },
    {
      title: "Make a claim",
      link: "/claims",
    },
  ];

  const [lastScrollY, setLastScrollY] = useState(0);
  const [scrollingDown, setScrollingDown] = useState(false);
  const threshold = 300;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > lastScrollY && currentScrollY > threshold) {
        setScrollingDown(true);
      } else {
        setScrollingDown(false);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <div
      className={`fixed top-0 w-full left-0 z-30 ${bgColor} mx-auto shadow-sm transition-transform duration-300 ${
        scrollingDown ? "-translate-y-full" : "translate-y-0"
      }`}
    >
      <nav className="relative max-w-[90rem] mx-auto z-20 flex shrink-0 items-center space-x-2  py-6 px-4 sm:px-6">
        <a href="/">
          {/* Logo */}
          <div>
            <img className="w-24" src={logo} alt="" />
          </div>
        </a>
        <div className="flex-1"></div>
        <div className="hidden space-x-1.5 md:flex">
          {navData.map((item) => (
            <NavLink
              key={item.link}
              to={item.link}
              className={({ isActive }) =>
                `inline-flex relative group cursor-pointer items-center justify-center px-4 py-2.5 text-base font-medium ${textColor} ${
                  isActive ? "font-bold border-b-2 border-current" : ""
                }`
              }
            >
              {item.title}
              <span
                className={`absolute -bottom-0 left-0 w-0 transition-all h-0.5 ${hoverColor} group-hover:w-full`}
              ></span>
            </NavLink>
          ))}
        </div>

        <Menu as="div" className="relative md:hidden z-50">
          <Menu.Button
            type="button"
            className={`inline-flex cursor-pointer items-center justify-center rounded-xl border-none border-transparent bg-transparent p-2 font-semibold ${textColor}`}
          >
            <HiMenuAlt3 className="h-7 w-7" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-xl bg-white py-3 shadow-xl focus:outline-none">
              {navData.map((item) => (
                <Menu.Item key={item.link}>
                  {({ active }) => (
                    <NavLink
                      to={item.link}
                      className={`${
                        active ? "bg-muted-1 text-heading" : "text-text"
                      } flex w-full cursor-pointer items-center px-4 py-2 text-sm font-semibold`}
                    >
                      {item.title}
                    </NavLink>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </nav>
    </div>
  );
};

export default NavHeader;
