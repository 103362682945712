export const ProfessionalIndeminityRequest = {
  category: "Business Protection",
  plan: "Professional Indemnity",
  professionalindeminityfields: [
    {
      label: "Full Name",
      name: "fullName",
      type: "text",
      required: true,
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      type: "text",
      required: true,
    },
    {
      label: "Email Address",
      name: "email",
      type: "email",
      required: true,
    },
    {
      label: "Name of Company",
      name: "nameOfCompany",
      type: "text",
      required: true,
    },
    {
      label: "Address of Company",
      name: "addressCompany",
      type: "text",
      required: true,
    },
    {
      label: "Description of professional services provided",
      name: "professionalServices",
      type: "text",
      required: true,
    },
    {
      label: "Value of Equipment and Machinery",
      name: "equipmentValue",
      type: "textarea",
      as: "textarea",
    },

    {
      label: "Limit of liability",
      name: "limitLiability",
      type: "text",
      required: true,
    },
    {
      label: "Number of professionals",
      name: "numberOfProfessionals",
      type: "number",
      required: true,
    },
  ],
};
