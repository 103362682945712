export const WorkersInsuranceRequest = {
  category: "Business Protection",
  plan: "Workers Compensation",
  workersInsurancefields: [
    {
      label: "Full Name",
      name: "fullName",
      type: "text",
      required: true,
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      type: "text",
      required: true,
    },
    {
      label: "Email Address",
      name: "email",
      type: "email",
      required: true,
    },
    {
      label: "Name of Business",
      name: "nameOfBusiness",
      type: "text",
      required: true,
    },
    {
      label: "Location of Business",
      name: "locationBusiness",
      type: "text",
      required: true,
    },
    {
      label: "Nature of business",
      name: "natureOfBusiness",
      type: "text",
      required: true,
    },

    {
      label: "Number of employees",
      name: "numberOfEmployees",
      type: "number",
      required: true,
    },
    {
      label: "Annual Salaries",
      name: "annualSalaries",
      type: "text",
      required: true,
    },
  ],
};
