export const MoneyInsuranceRequest = {
  category: "Business Protection",
  plan: "Money Insurance",
  moneyInsurancefields: [
    {
      label: "Full Name",
      name: "fullName",
      type: "text",
      required: true,
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      type: "text",
      required: true,
    },
    {
      label: "Email Address",
      name: "email",
      type: "email",
      required: true,
    },
    {
      label: "Name of Company",
      name: "nameOfCompany",
      type: "text",
      required: true,
    },
    {
      label: "Email Address of Company",
      name: "emailAddressCompany",
      type: "email",
      required: true,
    },
    {
      label: "Amount of cash handled",
      name: "amountOfCashHandled",
      type: "text",
      required: true,
    },
    {
      label: "Amount of cash in safe",
      name: "amountOfCashInSafe",
      type: "text",
      required: true,
    },
    {
      label: "Amount of cash in transit",
      name: "amountOfCashInTransit",
      type: "text",
      required: true,
    },
    {
      label: "Frequency and method of transportation of cash",
      name: "cashTransportation",
      type: "text",
      required: true,
    },
  ],
};
