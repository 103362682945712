import React from "react";
import { CiStar, CiHeart } from "react-icons/ci";
import { EffectFade, Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { MdPlusOne } from "react-icons/md";

const WhyChooseBanbo = () => {
  const whyChooseBanboData = [
    {
      icon: <CiStar className="text-4xl" />,
      bgcolor: "#FFC6DB",
      content:
        "Our mandate is to give our clients an easy and satisfying customer journey for all their insurance interests",
    },
    {
      icon: <MdPlusOne className="text-4xl" />,
      bgcolor: "#FFEFBE",
      content:
        "Our priority is to understand our clients’ businesses, the associated risks and insurance needs in order to recommend the most appropriate insurance solutions",
    },
    {
      icon: <CiHeart className="text-4xl" />,
      bgcolor: "#D1EAFE",
      content:
        "Our commitment is to established healthy relationships within the insurance industry and its affiliates to be able to collaborate and provide satisfaction to our clients",
    },
  ];

  const chooseImage = [
    {
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720609925/wcu1_xqtfpb.webp",
    },
    {
      image:
        "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720609922/wcu2_swzvk5.webp",
    },
  ];
  return (
    <div>
      <section className="py-10 overflow-x-hidden">
        <div className=" mx-auto max-w-[90rem] ">
          <h1
            data-aos="fade-up"
            // data-aos-offset="200"
            data-aos-delay="200"
            data-aos-duration="1000"
            data-aos-once="true"
            className="text-3xl px-6 md:text-4xl font-bold my-8 text-[#003862] "
          >
            Why choose Banbo
          </h1>

          <div className="grid items-center grid-cols-1 mt-6 gap-y-10 lg:grid-cols-5 sm:mt-20 gap-x-4">
            <div className="space-y-4 lg:pr-16 lg:col-span-2">
              {whyChooseBanboData.map((data, index) => (
                <div
                  data-aos="fade-right"
                  data-aos-offset="200"
                  data-aos-duration="800"
                  data-aos-once="true"
                  data-aos-delay={index * 200}
                  key={index}
                  className="flex items-start bg-[#F9F9F9] p-6 m-2 rounded-2xl"
                >
                  <div
                    className="flex items-center flex-shrink justify-center w-10 h-10 rounded-full"
                    style={{ backgroundColor: `${data.bgcolor}` }}
                  >
                    {data.icon}
                  </div>
                  <div className="ml-5">
                    <p className=" font-medium text-md text-gray-600">
                      {data.content}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="lg:col-span-3">
              <Swiper
                spaceBetween={30}
                loop={true}
                effect={"fade"}
                navigation={false}
                autoplay={{
                  delay: 10000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[EffectFade, Navigation, Pagination, Autoplay]}
                className="mySwiper hidden lg:flex"
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-delay="800"
                data-aos-duration="1000"
                data-aos-once="true"
              >
                {" "}
                {chooseImage.map((data, index) => (
                  <SwiperSlide key={index}>
                    <img
                      className="w-full rounded-lg shadow-xl"
                      src={data.image}
                      alt=""
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhyChooseBanbo;
