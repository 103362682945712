import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import HomePage from "../presentation/pages/landingPage/homepage/HomePage";
import LandingPageLayout from "../shared/LandingPageLayout";
import About from "../presentation/pages/landingPage/about/About";
import BuyInsurance from "../presentation/pages/landingPage/insurance/buyInsurance/BuyInsurance";
import Claims from "../presentation/pages/landingPage/claim/Claims";

import PrivacyPolicy from "../presentation/pages/landingPage/PrivacyPolicy";
import InsuranceService from "../presentation/pages/landingPage/insurance/insuranceService/InsuranceService";
import QuotePlan from "../presentation/pages/landingPage/insurance/quoteItems/QuotePlan";

import IndividualFamily from "../presentation/pages/landingPage/insurance/buyInsurance/components/IndividualFamily";
import GroupProtection from "../presentation/pages/landingPage/insurance/buyInsurance/components/GroupProtection";
import BusinessProtection from "../presentation/pages/landingPage/insurance/buyInsurance/components/BusinessProtection";
import SpecialProtection from "../presentation/pages/landingPage/insurance/buyInsurance/components/SpecialProtection";
import PageNotFound from "../presentation/pages/PageNotFound";
import QuoteSuccessful from "../presentation/pages/landingPage/insurance/quoteItems/QuoteSuccessful";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPageLayout />}>
        <Route index element={<Navigate replace to="home" />} />
        <Route path="home" element={<HomePage />} />
        <Route path="about" element={<About />} />
        <Route path="insurance-quote/:title" element={<QuotePlan />} />
        <Route path="insurance-quote/success/:id" element={<QuoteSuccessful />} />
        <Route path="claims" element={<Claims />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="insurance-service/:title" element={<InsuranceService />} />

        <Route path="/buy-insurance" element={<BuyInsurance />}>
          <Route index element={<Navigate replace to="individual-family" />} />
          {/* <Route path="plan/:title/:id" element={<Plan />} /> */}
          <Route path="individual-family" element={<IndividualFamily />} />
          <Route path="group-protection" element={<GroupProtection />} />
          <Route path="business-protection" element={<BusinessProtection />} />
          <Route path="special-protection" element={<SpecialProtection />} />
        </Route>
      </Route>

      {/* Page not found route */}
      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppRouter;
