export const healthRequest = {
  insuranceTypeEnum: "Health_WEB",
  product: undefined,
  category: "Individual & Family",
  plan: "Health Plan",
  healthfields: [
    {
      label: "First name",
      name: "firstName",
      type: "text",
      required: true,
    },
    {
      label: "Other name",
      name: "otherNames",
      type: "text",
      required: true,
    },

    {
      label: "Surname",
      name: "surname",
      type: "text",
      required: true,
    },
    {
      label: "Date of birth",
      name: "dateOfBirth",
      type: "date",
      required: true,
    },
    {
      label: "Location/Address",
      name: "location",
      type: "text",
      required: true,
    },

    {
      label: "Select Package",
      name: "healthPackageEnum",
      type: "select",
      required: true,
      options: [
        { key: "APOMUDEN_MERCURY", value: "APOMUDEN MERCURY" },
        { key: "APOMUDEN_PLATINUM", value: "APOMUDEN PLATINUM" },
        { key: "APOMUDEN_PLATINUM_PLUS", value: "APOMUDEN PLATINUM PLUS" },
      ],
    },
    {
      label: "Benefit option",
      name: "benefitOption",
      type: "select",
      options: [{ key: "dummy", value: "dummy data" }],
    },
    {
      label: "Relationship",
      name: "relationship",
      type: "select",
      options: [{ key: "PRINCIPAL_MEMBER", value: "PRINCIPAL MEMBER" }],
    },
    {
      label: "Medical history",
      name: "medicalHistory",
      type: "textarea",
      as: "textarea",
      required: true,
    },
  ],
};
