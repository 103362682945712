export const PlantMachineryRequest = {
  category: "Business Protection",
  plan: "Plant & Machinery",
  plantmachineryfields: [
    {
      label: "Full Name",
      name: "fullName",
      type: "text",
      required: true,
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      type: "text",
      required: true,
    },
    {
      label: "Email Address",
      name: "email",
      type: "email",
      required: true,
    },
    {
      label: "Name of Company",
      name: "nameOfCompany",
      type: "text",
      required: true,
    },
    {
      label: "Address of Company",
      name: "AddressCompany",
      type: "text",
      required: true,
    },
    {
      label: "Type of Equipment and Machinery",
      name: "equipmentType",
      type: "text",
      required: true,
    },
    {
      label: "Value of Equipment and Machinery",
      name: "equipmentValue",
      type: "text",
      required: true,
    },
    {
      label: "Usage of machinery",
      name: "vehicle-category",
      type: "select",
      options: [
        { key: "ONSITE", value: "Onsite" },
        { key: "VARIOUS_LOCATIONS", value: "Various Locations" },
      ],
    },
  ],
};
