export const travelRequest = {
  // title: 'Sample Form',
  insuranceTypeEnum: "TRAVEL",
  product: undefined,
  category: "Individual & Family",
  plan: "Travel Plan",
  travelfields: [
    {
      label: "Select coverage",
      name: "coverType",
      type: "select",
      required: true,
      typeHasApiCall: true,
    },
    { label: "Gender", name: "gender", type: "text", required: true },
    { label: "Nationality", name: "nationality", type: "text", required: true },
    { label: "Address", name: "address", type: "text", required: true },
    {
      label: "Departure date",
      name: "departureDate",
      type: "date",
      required: true,
    },
    { label: "Return date", name: "returnDate", type: "date", required: true },
    {
      label: "Destination country",
      name: "destinationCountry",
      type: "text",
      required: true,
    },
    {
      label: "Destination address",
      name: "destinationAddress",
      type: "text",
      required: true,
    },
    {
      label: "Destination contact number",
      name: "destinationContactNo",
      type: "text",
      required: true,
    },
    {
      label: "Passport number",
      name: "passportNumber",
      type: "text",
      required: true,
    },
    {
      label: "Passport expiry date",
      name: "passportExpiryDate",
      type: "date",
      required: true,
    },
    {
      label: "Address of embassy for which VISA is applied for",
      name: "addressEmbassy",
      type: "text",
      required: true,
    },
    {
      label: "Beneficiary full name",
      name: "beneficiaryFullName",
      type: "text",
      required: true,
    },
    {
      label: "Beneficiary phone number",
      name: "beneficiaryPhoneNumber",
      type: "text",
      required: true,
    },
    {
      label: "Relationship with beneficiary",
      name: "relationshipWithBeneficiary",
      type: "text",
      required: true,
    },
    {
      label: "Travel package",
      name: "travelPackageEnum",
      type: "select",
      required: true,
      options: [
        { key: "ONE_WEEK", value: "1 Week" },
        { key: "TWO_WEEKS", value: "2 Weeks" },
        { key: "THREE_WEEKS", value: "3 Weeks" },
        { key: "ONE_MONTH", value: "1 Month" },
        { key: "TWO_MONTHS", value: "2 Months" },
        { key: "THREE_MONTHS", value: "3 Months" },
        { key: "SIX_MONTHS", value: "6 Months" },
        { key: "ONE_YEAR", value: "1 Year" },
      ],
    },
  ],
};
