import React from "react";

const Journey = () => {
  return (
    <div>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-[90rem] md:px-24 lg:px-8 lg:py-20">
        <div className="flex flex-col  overflow-hidden bg-white lg:flex-row sm:mx-auto">
          <div className="relative lg:w-1/2">
            <img
              data-aos="fade-right"
              data-aos-offset="200"
              data-aos-delay="200"
              data-aos-duration="1000"
              data-aos-once="true"
              src="https://res.cloudinary.com/dzitjtuoj/image/upload/v1720610398/img_about_story_x8xmcu.webp"
              alt=""
              className="object-cover object-[15%] rounded-3xl w-full h-full"
            />
          </div>
          <div
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-delay="200"
            data-aos-duration="1000"
            data-aos-once="true"
            className="flex flex-col justify-center px-4 pt-4 lg:pt-0 lg:px-12 bg-white lg:w-1/2"
          >
            <p className="mb-6 font-medium text-[#003862]">
              Established in 2019, Banbo Insurance Brokers Ltd embarked on a
              mission to redefine the landscape of insurance brokerage, merging
              traditional expertise with the transformative power of Insurtech.
            </p>
            <p className="mb-6 font-medium text-[#003862]">
              Our journey began with a clear mandate - to empower individuals
              and businesses with the indispensable tool of financial protection
              while fostering a community of unwavering financial confidence. We
              recognized the profound impact that sound financial planning and
              insurance decisions can have on the lives of our clients. Guided
              by this principle, we set out to provide not just insurance
              products, but comprehensive solutions tailored to the unique needs
              of each individual and organization we serve.
            </p>
            <p className="mb-6 font-medium text-[#003862]">
              Central to our approach is a deep understanding of the dynamic and
              ever- evolving insurance industry. Our team brings together a
              wealth of practical experience gained from diverse roles within
              the ecosystem. This collective expertise equips us with a unique
              perspective and an unparalleled ability to navigate the
              complexities of insurance with precision and insight.
            </p>
            <p className="mb-6 font-medium text-[#003862]">
              At Banbo, innovation is not just a buzzword—it's a way of life.
              Leveraging our group leadership in financial technology, we have
              embraced the latest advancements to deliver accessible,
              convenient, and tailored insurance solutions to our clients
            </p>

            <p className="mb-6 font-medium text-[#003862]">
              Nonetheless, our journey is not just about delivering products and
              services—it's about building lasting relationships based on trust,
              integrity, and professionalism. We are confident about our
              client-centric approach, which places the needs and aspirations of
              our clients at the forefront of everything we do. Whether it's
              guiding a family through the intricacies of life insurance or
              helping a business mitigate risk with comprehensive coverage, we
              are committed to exceeding expectations and delivering results
              that matter.
            </p>
            <p className="mb-6 font-medium text-[#003862]">
              As we look to the future, we are dedicated to continuous learning,
              innovation, and agility, ensuring that we remain at the forefront
              of industry trends and developments.
            </p>
            <p className=" font-medium text-[#003862]">
              Join us on this journey towards financial confidence and
              security—because with Banbo, your peace of mind is truly our
              priority.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Journey;
