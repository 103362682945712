import React from "react";
import { useLocation } from "react-router-dom";
import MotorVehicleQuoteSidebar from "./motorVehicleQuote/MotorVehicleQuoteSidebar";
import MoneyInsuranceQuoteSidebar from "./moneyInsuranceQuote/MoneyInsuranceQuoteSidebar";
import QuoteForms from "./QuoteForms";
import { MoneyInsuranceRequest } from "./moneyInsuranceQuote/MoneyInsuranceQuoteData";
import { MotorVehicleRequest } from "./motorVehicleQuote/MotoVehicleQuoteData";
import PlantMachineryQuoteSidebar from "./plantAndMachinery/PlantMachineryQuoteSidebar";
import { PlantMachineryRequest } from "./plantAndMachinery/PlantMachineryQuoteData";
import AssetsAllRisksQuoteSidebar from "./assetsAllRisks/AssetsAllRisksQuoteSidebar";
import { AssetsAllRisksRequest } from "./assetsAllRisks/AssetsAllRisksData";
import CyberInsuranceQuoteSidebar from "./cyberInsurance/CyberInsuranceQuoteSidebar";
import { cyberInsuranceRequest } from "./cyberInsurance/CyberInsuranceQuoteData";
import ContractorRisksQuoteSidebar from "./contractorsRisk/ContractorRisksQuoteSidebar";
import { ContractorRiskRequest } from "./contractorsRisk/ContractorRiskData";
import MarineQuoteSidebar from "./marine/MarineRisksQuoteSidebar";
import { MarineRequest } from "./marine/MarineData";
import ProfessionalIndeminitySidebar from "./professionalIndeminity/ProfessionalIndeminitySidebar";
import { ProfessionalIndeminityRequest } from "./professionalIndeminity/ProfessionalIndeminityData";
import WorkersInsuranceSidebar from "./workersInsurance/WorkersInsuranceSidebar";
import { WorkersInsuranceRequest } from "./workersInsurance/WorkersInsuranceData";
import GroupHealthQuoteSidebar from "./groupHealth/GroupHealthQuoteSidebar";
import { GroupHealthRequest } from "./groupHealth/GroupHealthData";
import GroupLifeSidebar from "./groupLife/GroupLifeSidebar";
import { GroupLifeRequest } from "./groupLife/GroupLifeData";
import GroupTravelSidebar from "./groupTravel/GroupTravelSidebar";
import { GroupTravelRequest } from "./groupTravel/GroupTravelData";
import GroupWelfareSidebar from "./groupWelfare/GroupWelfareSidebar";
import { GroupWelfareRequest } from "./groupWelfare/GroupWelfareData";
import MotorSidebar from "./MotorPlan.tsx/MotorSidebar";
import HealthSidebar from "./healthPlan/HealthSidebar";
import TravelSidebar from "./TravelPlan.tsx/TravelSidebar";
import HomeOwnerSidebar from "./homeowners/HomeOwnerSidebar";
import ShopSolidSidebar from "./shopSolid/ShopSolidSidebar";
import PharmaSecureSidebar from "./pharmaSecure/PharmaSecureSidebar";
import PlannersProtectionSidebar from "./plannersProtection/PlannersProtectionSidebar";
import SchoolInsuranceSidebar from "./schoolInsurance/SchoolInsuranceSidebar";
import { schoolInsuranceRequest } from "./schoolInsurance/SchoolInsuranceData";
import { plannersProtectionRequest } from "./plannersProtection/PlannerProtectionData";
import { shopSolidRequest } from "./shopSolid/ShopSolidInsurancePlanData";
import { pharmaSecureRequest } from "./pharmaSecure/PharmaSecurePlanData";
import { homeOwnerRequest } from "./homeowners/HomeOwnerInsurancePlanData";
import { travelRequest } from "./TravelPlan.tsx/TravelPlanData";
import { motorRequest } from "./MotorPlan.tsx/MotorInsurancePlanData";
import { healthRequest } from "./healthPlan/HealthInsurancePlanData";

const quotes = {
  "/insurance-quote/Motor-Plan": {
    sidebar: <MotorSidebar />,
    form: (
      <QuoteForms request={motorRequest} fields={motorRequest.motorfields} />
    ),
    name: "Motor Plan",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615431/img_motor_plan_icqsre.webp",
  },
  "/insurance-quote/Health-Plan": {
    sidebar: <HealthSidebar />,
    form: (
      <QuoteForms request={healthRequest} fields={healthRequest.healthfields} />
    ),
    name: "Health Plan",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615386/img_health_Insurance_fuxaxg.webp",
  },
  "/insurance-quote/Travel-Plan": {
    sidebar: <TravelSidebar />,
    form: (
      <QuoteForms request={travelRequest} fields={travelRequest.travelfields} />
    ),
    name: "Travel Plan",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615449/img_travel_icai9t.webp",
  },
  "/insurance-quote/Homeowners-Plan": {
    sidebar: <HomeOwnerSidebar />,
    form: (
      <QuoteForms
        request={homeOwnerRequest}
        fields={homeOwnerRequest.homeownerfields}
      />
    ),
    name: "Homeowners Plan",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615386/img_homeowners_fnucyj.webp",
  },
  "/insurance-quote/PharmaSecure": {
    sidebar: <PharmaSecureSidebar />,
    form: (
      <QuoteForms
        request={pharmaSecureRequest}
        fields={pharmaSecureRequest.pharmasecurefields}
      />
    ),
    name: "PharmaSecure",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615435/img_pharmasecure_oalhua.webp",
  },
  "/insurance-quote/ShopSolid": {
    sidebar: <ShopSolidSidebar />,
    form: (
      <QuoteForms
        request={shopSolidRequest}
        fields={shopSolidRequest.shopsolidfields}
      />
    ),
    name: "ShopSolid",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615449/img_shopsolid_mobwii.webp",
  },
  "/insurance-quote/Planners-Protection": {
    sidebar: <PlannersProtectionSidebar />,
    form: (
      <QuoteForms
        request={plannersProtectionRequest}
        fields={plannersProtectionRequest.plannersprotectionfields}
      />
    ),
    name: "Planners Protection",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615438/img_planners_protection_bumsu6.jpg",
  },
  "/insurance-quote/School-Insurance": {
    sidebar: <SchoolInsuranceSidebar />,
    form: (
      <QuoteForms
        request={schoolInsuranceRequest}
        fields={schoolInsuranceRequest.Schoolinsurancefields}
      />
    ),
    name: "School Protection",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615448/img_school_insurance_fhan7f.webp",
  },
  "/insurance-quote/Motor-Vehicle-&-Fleet": {
    sidebar: <MotorVehicleQuoteSidebar />,
    form: (
      <QuoteForms
        request={MotorVehicleRequest}
        fields={MotorVehicleRequest.motorvehiclefields}
      />
    ),
    name: "Motor Vehicle & Fleet",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615431/img_motor-vehicle-fleet_yhsolm.webp",
  },
  "/insurance-quote/Money-Insurance": {
    sidebar: <MoneyInsuranceQuoteSidebar />,
    form: (
      <QuoteForms
        request={MoneyInsuranceRequest}
        fields={MoneyInsuranceRequest.moneyInsurancefields}
      />
    ),
    name: "Money Insurance",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615427/img_money-insurance_lwedf2.webp",
  },
  "/insurance-quote/Plant-&-Machinery": {
    sidebar: <PlantMachineryQuoteSidebar />,
    form: (
      <QuoteForms
        request={PlantMachineryRequest}
        fields={PlantMachineryRequest.plantmachineryfields}
      />
    ),
    name: "Plant & Machinery",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615442/img_plant-machinery_aokqrj.webp",
  },
  "/insurance-quote/Assets-All-Risks": {
    sidebar: <AssetsAllRisksQuoteSidebar />,
    form: (
      <QuoteForms
        request={AssetsAllRisksRequest}
        fields={AssetsAllRisksRequest.assetsrisksfields}
      />
    ),
    name: "Assets All Risks",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615375/img_asset-all-risk_e9vvn0.webp",
  },
  "/insurance-quote/Cyber-Insurance": {
    sidebar: <CyberInsuranceQuoteSidebar />,
    form: (
      <QuoteForms
        request={cyberInsuranceRequest}
        fields={cyberInsuranceRequest.cyberInsurancefields}
      />
    ),
    name: "Cyber Insurance",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615376/img_cyber-insurance_quxefv.webp",
  },
  "/insurance-quote/Contractors-All-Risk": {
    sidebar: <ContractorRisksQuoteSidebar />,
    form: (
      <QuoteForms
        request={ContractorRiskRequest}
        fields={ContractorRiskRequest.contractorsriskfields}
      />
    ),
    name: "Contractors All Risk",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615375/img_contractors_risk_apkedh.webp",
  },
  "/insurance-quote/Marine": {
    sidebar: <MarineQuoteSidebar />,
    form: (
      <QuoteForms request={MarineRequest} fields={MarineRequest.marinefields} />
    ),
    name: "Marine",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615424/img_marine-insurance_pe1h1t.webp",
  },
  "/insurance-quote/Professional-Indemnity": {
    sidebar: <ProfessionalIndeminitySidebar />,
    form: (
      <QuoteForms
        request={ProfessionalIndeminityRequest}
        fields={ProfessionalIndeminityRequest.professionalindeminityfields}
      />
    ),
    name: "Professional Indemnity",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615442/img_professional-indemnity_x4egfr.webp",
  },
  "/insurance-quote/Workers-Compensation": {
    sidebar: <WorkersInsuranceSidebar />,
    form: (
      <QuoteForms
        request={WorkersInsuranceRequest}
        fields={WorkersInsuranceRequest.workersInsurancefields}
      />
    ),
    name: "Workers Compensation",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615456/img_workers-compensation_zda0ed.webp",
  },
  "/insurance-quote/Group-Health": {
    sidebar: <GroupHealthQuoteSidebar />,
    form: (
      <QuoteForms
        request={GroupHealthRequest}
        fields={GroupHealthRequest.grouphealthfields}
      />
    ),
    name: "Group Health",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615377/img_group-health_trlz5n.webp",
  },
  "/insurance-quote/Group-Life": {
    sidebar: <GroupLifeSidebar />,
    form: (
      <QuoteForms
        request={GroupLifeRequest}
        fields={GroupLifeRequest.grouplifefields}
      />
    ),
    name: "Group Life",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615381/img_group-life-insurance_mryohk.webp",
  },
  "/insurance-quote/Group-Travel": {
    sidebar: <GroupTravelSidebar />,
    form: (
      <QuoteForms
        request={GroupTravelRequest}
        fields={GroupTravelRequest.grouptravelfields}
      />
    ),
    name: "Group Travel",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615380/img_group-travel_gths5q.jpg",
  },
  "/insurance-quote/Group-Welfare": {
    sidebar: <GroupWelfareSidebar />,
    form: (
      <QuoteForms
        request={GroupWelfareRequest}
        fields={GroupWelfareRequest.groupwelfarefields}
      />
    ),
    name: "Group Welfare",
    image:
      "https://res.cloudinary.com/dzitjtuoj/image/upload/v1720615382/img_group-welfare_f25y3d.webp",
  },
};

const QuotePlan = () => {
  const { pathname } = useLocation();
  const quote = quotes[pathname];

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${quote?.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="bg-[#003862] h-[60vh] w-full flex items-center justify-center bg-opacity-80">
          <div className="px-4 sm:px-6 lg:px-8 py-10 space-y-8">
            <div className="text-center mx-auto">
              <h1 className="block font-bold text-gray-200 text-4xl sm:text-5xl">
                {quote ? quote.name : "This policy would be available soon"}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 py-6 md:py-12 mx-auto sm:max-w-xl md:max-w-full lg:max-w-7xl ">
        <div className="grid gap-3 gap-x-8 sm:grid-cols-2 lg:grid-cols-3 items-stretch">
          {quote && (
            <>
              {quote.sidebar}
              {quote.form}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuotePlan;
