import React, { useMemo } from "react";
import { ArrowRight } from "../../../../../assets";
import { useGetAllInsurancePlansQuery } from "../../../../../../services/insurance-plans-service";
import InsuranceShimmer from "./InsuranceShimmer";
import { Link } from "react-router-dom";

const SpecialProtection = () => {
  const { data: response, isLoading } = useGetAllInsurancePlansQuery();
  console.log(response);
  const specialProtectionProducts = useMemo(() => {
    if (!response || !response.data) {
      return [];
    }
    const category = response.data.find(
      (item) => item.categoryEnum === "SPECIAL_PRODUCT"
    );
    return category ? category.insuranceProducts : [];
  }, [response]);

  if (isLoading) {
    return (
      <div>
        <InsuranceShimmer />
      </div>
    );
  }

  const handleClick = (id) => {
    sessionStorage.setItem("selectedInsuranceProductId", id);
  };

  return (
    <div className="grid gap-5  row-gap-5 sm:grid-cols-2 my-4">
      {specialProtectionProducts.map((product) => (
        <Link
          to={`/insurance-quote/${product.name.replace(/\s+/g, "-")}`}
          key={product.id}
          onClick={() => handleClick(product.id)}
          className="max-w-sm cursor-pointer group bg-[#f9f9f9] hover:bg-[#D1EAFE] rounded-3xl p-6"
        >
          <div className="grid grid-cols-[0fr,2fr]">
            <div className="flex items-center justify-center w-8 h-8 bg-[#d2d1d1] rounded-full">
              <img
                src={product.icon}
                alt={product.name}
                className="w-5 h-5 mx-auto "
              />
            </div>
            <div className="ml-2">
              <h6 className="mb-2 font-semibold leading-5">{product.name}</h6>
              <p className="text-sm mb-6 text-gray-700">
                {product.description}
              </p>
              <div className=" flex underline font-semibold text-[#31A5F8] hover:text-sky-600 transition ease-in-out duration-200">
                Get Policy
                <span
                  aria-hidden="true"
                  className=" translate-x-0 group-hover:translate-x-3 transition-transform ease-in-out duration-200"
                >
                  <img src={ArrowRight} alt="" />
                </span>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default SpecialProtection;
