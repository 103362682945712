export const GroupHealthRequest = {
  category: "Group Protection",
  plan: "Group Health",
  grouphealthfields: [
    {
      label: "Full Name",
      name: "fullName",
      type: "text",
      required: true,
    },
    {
      label: "Phone Number",
      name: "phoneNumber",
      type: "text",
      required: true,
    },
    {
      label: "Email Address",
      name: "email",
      type: "email",
      required: true,
    },
    {
      label: "Name of company",
      name: "nameOfCompany",
      type: "text",
      required: true,
    },
    {
      label: "Address of company",
      name: "addressOfCompany",
      type: "text",
      required: true,
    },
    {
      label: "Nature of business",
      name: "natureOfBusiness",
      type: "textarea",
      as: "textarea",
      required: true,
    },
    {
      label: "Number of employees or members",
      name: "numberOfEmployees",
      type: "text",
      required: true,
    },
    {
      label: "Age Range",
      name: "ageRange",
      type: "text",
      required: true,
    },
  ],
};
