export const shopSolidRequest = {
  insuranceTypeEnum: "SHOPSOLID",
  product: undefined,
  category: "Special Protection",
  plan: "Shop Solid",
  shopsolidfields: [
    {
      label: "Value of building",
      name: "buildingValue",
      type: "text",
      required: true,
    },
    {
      label: "Total value of items",
      name: "itemValue",
      type: "text",
      required: true,
    },
    { label: "Location", name: "shopLocation", type: "text", required: true },
    {
      label: "Nature of Stock and Goods",
      name: "natureOfStockAndGoods",
      type: "text",
      required: true,
    },
    {
      label: "Type of cover",
      name: "coverType",
      type: "select",
      typeHasApiCall: true,
      required: true,
    },
    {
      label: "What is your shop built of",
      name: "shopBuild",
      type: "select",
      required: true,
      options: [
        { key: "Cement", value: "Cement Blocks" },
        { key: "Bricks", value: "Bricks" },
        { key: "Container", value: "Metal container" },
        { key: "Wood", value: "Wood" },
      ],
    },
    {
      label: "Certificate of Incorporation",
      name: "COI",
      type: "file",
      required: true,
    },
    {
      label: "Stock List",
      name: "SL",
      type: "file",
      required: true,
    },
  ],
};
