import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { IoCloseCircle } from "react-icons/io5";

const QuoteUserDetails = ({
  isOpen,
  onClose,
  requestor,
  updateRequestor,
  onSubmit,
}) => {
  const [requestorDetails, setRequestorDetails] = useState(requestor);

  useEffect(() => {
    console.log("Received requestor:", requestor); // Debugging
    setRequestorDetails(requestor);
  }, [requestor]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedDetails = { ...requestorDetails, [name]: value };
    setRequestorDetails(updatedDetails);
    updateRequestor(updatedDetails); // Update the parent immediately
  };

  const handleSubmit = () => {
    console.log("Submitting requestor details:", requestorDetails); // Debugging
    onSubmit();
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 flex min-h-screen items-end justify-center overflow-hidden px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative inline-flex w-full transform flex-col overflow-hidden rounded-3xl bg-white text-left align-bottom  transition-all sm:my-8 sm:max-w-md sm:align-middle">
                <div className="absolute top-4 right-5">
                  <button
                    type="button"
                    onClick={onClose}
                    className="inline-flex aspect-square cursor-pointer items-center justify-center rounded-xl border-none border-transparent bg-transparent p-2 font-semibold text-text hover:bg-heading/5 focus:bg-heading/5 focus:outline-none focus:ring-2 focus:ring-heading/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:bg-transparent disabled:hover:text-text child-svg:h-5 child-svg:w-5"
                  >
                    <span className="sr-only">Close</span>
                    <IoCloseCircle className="h-5 w-5" />
                  </button>
                </div>

                <div className="flex h-16 flex-shrink-0 items-center justify-between px-6">
                  <Dialog.Title
                    as="h3"
                    className="text-lg flex items-center gap-2 font-bold"
                  >
                    Customer Details
                  </Dialog.Title>
                </div>

                <hr className="border-hr" />
                <div className="px-4 lg:px-8">
                  <label
                    htmlFor="name"
                    className="inline-block text-sm font-medium text-[#003862] mt-4"
                  >
                    Name
                  </label>
                  <input
                    name="name"
                    value={requestorDetails.name}
                    onChange={handleChange}
                    placeholder="Name"
                    required
                    className="mt-2 py-4 px-3 block w-full border-2 border-gray-200 shadow-sm rounded-lg bg-gray-50 text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                  />
                  <label
                    htmlFor="email"
                    className="inline-block text-sm font-medium text-[#003862] mt-4"
                  >
                    Email
                  </label>
                  <input
                    name="email"
                    value={requestorDetails.email}
                    onChange={handleChange}
                    placeholder="Email"
                    required
                    className="mt-2 py-4 px-3 block w-full border-2 border-gray-200 shadow-sm rounded-lg bg-gray-50 text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                  />

                  <label
                    htmlFor="phone"
                    className="inline-block text-sm font-medium text-[#003862] mt-4"
                  >
                    Phone
                  </label>
                  <input
                    name="phone"
                    value={requestorDetails.phone}
                    onChange={handleChange}
                    placeholder="Phone"
                    required
                    className="mt-2 py-4 px-3 block w-full border-2 border-gray-200 shadow-sm rounded-lg bg-gray-50 text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                  />

                  <hr className="border-hr" />
                  <div className="px-4 lg:px-8 mb-8">
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      className="mt-4 bg-blue-500 w-full text-white px-4 py-2 rounded"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default QuoteUserDetails;
