import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { authService } from "../services/auth-service";
import { insurancePlansService } from "../services/insurance-plans-service";
import { portalService } from "../services/portal-service";
import { quoteService } from "../services/quote-service";
import { customerEstimateService } from "../services/customerEstimate-service";

export const store = configureStore({
  reducer: {
    [authService.reducerPath]: authService.reducer,
    [insurancePlansService.reducerPath]: insurancePlansService.reducer,
    [portalService.reducerPath]: portalService.reducer,
    [quoteService.reducerPath]: quoteService.reducer,
    [customerEstimateService.reducerPath]: customerEstimateService.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authService.middleware,
      insurancePlansService.middleware,
      portalService.middleware,
      quoteService.middleware,
      customerEstimateService.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
